import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../styles/index.module.css"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <div className={styles.topWrapper}>
      <div className={styles.imageWrapper}>
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>

      <div>
        <h1>Entdecke meinen Beauty Point!</h1>
        <p>
          Als Vital- und Gesundheitspräventionscoach sowie Beauty- &
          Make-Up-Profi biete ich dir in meinen Beauty Point individuelle
          Beratung und persönliche Betreuung zu den Themen Gesundheit und
          Schönheit
        </p>
      </div>
    </div>

    <hr />

    <h1>Meine Services</h1>
    <section className={styles.services}>
      {data.service.nodes.map(data => {
        return (
          <div className={`${styles.card} ${styles.shadow}`} key={data.id}>
            <div className={styles.cardImage}>
              <Img fluid={data.image.fluid} />
            </div>
            <h2 className={styles.cardTitle}>{data.title}</h2>
            <div
              className={styles.cardText}
              dangerouslySetInnerHTML={{
                __html: data.text.childMarkdownRemark.html,
              }}
            />
            <Link to={`/${data.link}/`} className={styles.cardLink}>
              erfahre mehr
            </Link>
          </div>
        )
      })}
    </section>
  </Layout>
)

export const query = graphql`
  {
    file(relativePath: { eq: "index_top.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    service: allContentfulServices(sort: { order: ASC, fields: order }) {
      nodes {
        link
        id
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default IndexPage
